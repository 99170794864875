import React, { useState } from 'react';
import styled from 'styled-components';
import TabsBody from './TabsBody';
import TabsHeader from './TabsHeader';

const TabsSectionStyled = styled.section`
`;

const TabsSection = () => {

  const [activeTab, setActiveTab] = useState(0);

  const tabsArr = [
    {id: 0, title: 'Танцюристам'},
    {id: 1, title: 'Організаторам'},
    {id: 2, title: 'Тренерам та суддям'},
    {id: 3, title: 'Клубам та організаціям'},
  ];

  return (
    <TabsSectionStyled>
      <TabsHeader activeTab={activeTab} setActiveTab={setActiveTab} tabsArr={tabsArr}/>
      <TabsBody activeTab={activeTab} setActiveTab={setActiveTab} tabsArr={tabsArr}/>
    </TabsSectionStyled>
  )
}

export default TabsSection;