import React from 'react';
import styled from 'styled-components';

const SerarchBlockStyled = styled.div`
  width: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    max-width: 100%;
  }

  @media(max-width: 992px) {
    display: none;
  }
`;

const SerarchBlock = () => {
  return (
    <SerarchBlockStyled><img src='/icons/search.svg' alt='search'></img></SerarchBlockStyled>
  )
}

export default SerarchBlock;