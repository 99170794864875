import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Container from '../container/Container';

const TabsHeaderStyled = styled.div`
  min-height: 100px;
  background: #223344;

  @media(max-width: 992px) { 
    min-height: 70px;
  }
`;

const TabsList = styled.ul`
  list-style: none;
  padding: 35px 0;
  display: flex;

  @media(max-width: 992px) {
    width: ${p => `${p.width}px`};
    padding: 25px 0;
  }
`;

const TabsListItem = styled.li`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${p => p.isActive ? 'bold' : '500'};
  font-size: ${p => p.isActive ? '18' : '16'};
  line-height: 30px;
  letter-spacing: 0.1em;
  color: #FFFFFF;
  text-align: center;
  border-left: ${p => p.isActive || p.prevIsActive ? '2.5px solid #FFFFFF' : '1.5px solid #FFFFFF'};
  cursor: pointer;
  :last-child {
    border-right: ${p => p.isActive ? '2.5px solid #FFFFFF' : '1.5px solid #FFFFFF'};
  }

  @media(max-width: 992px) {
    position: relative;
    flex: unset;
    border: none;
    padding-right: 32px;
    margin-right: 24px;
    font-size: ${p => p.isActive ? '16' : '14'};
    line-height: 20px;
    white-space: nowrap;
    :after {
      content: '';
      width: 8px;
      height: 20px;
      position: absolute;
      top: calc(50% - 10px);
      right: 0;
      background: url('/icons/arrow-white.svg');
    }
    :last-child {
    border-right: none;
    :after {
      display: none;
    }
  }
  }
`;

const TabsListWrapper = styled.div`
  overflow: auto;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const TabsHeader = ({activeTab, setActiveTab, tabsArr}) => {

  const [tabsWidth, setTabsWidth] = useState(0);

  const tabs = useRef(null);

  useEffect(()=>{
    let tabsListWidth = 0;
    const tabsList = document.querySelectorAll('.tabs_item');
    const tabsOffSet = tabs.current.getBoundingClientRect().width - tabsList[tabsList.length - 1].getBoundingClientRect().width - 32;
    tabsList.forEach(item => tabsListWidth += item.getBoundingClientRect().width + 24);
    setTabsWidth(tabsListWidth + tabsOffSet);
  }, [])

  useEffect(()=>{
    let scrollWidth = 0;
    document.querySelectorAll('.tabs_item').forEach((item, i) => {
      if(i < activeTab) {
        scrollWidth += item.getBoundingClientRect().width + 24
      }
    });
    tabs.current.scroll(scrollWidth, 0);
  }, [activeTab]);

  return (
    <TabsHeaderStyled>
      <Container>
        <TabsListWrapper ref={tabs} >
          <TabsList width={tabsWidth}>
            {
              tabsArr.map((item, i)=>(
              <TabsListItem
                className='tabs_item'
                onClick={()=>setActiveTab(item.id)} 
                key={item.id} 
                isActive={item.id === activeTab} 
                prevIsActive={item.id === activeTab + 1}>
                {item.title}
              </TabsListItem>))
            }
          </TabsList>
        </TabsListWrapper>
      </Container>
    </TabsHeaderStyled>
  )
}

export default TabsHeader;