import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Container from '../container/Container';
import TabsBodyItem from './TabsBodyItem';
import TabsCircles from './TabsCircles';

const TabsBodyStyled = styled.div`
  padding: 58px 0 80px 0;
  background: #F3F5F7;
  min-height: 746px;

  @media(max-width: 992px) {
    padding-top: 16px;
  }
`;

const BodyWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 58px;

  @media(max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  @media(max-width: 576px) {
    grid-template-columns: 1fr;
    gap: 28px;
  }
`;

const TabsBody = ({activeTab, setActiveTab, tabsArr}) => {

  const [posStart, setPosStart] = useState(false);
  const [swipeLeft, setSwipeLeft] = useState(false);
  const [swipeRight, setSwipeRight] = useState(false);

  useEffect(()=>{
    if(swipeLeft) {
      setActiveTab(state => {
        if (state === 0) {
          return tabsArr.length - 1
        } 
        return state - 1  
      })
      setSwipeLeft(false);
    }

    if (swipeRight) {
      setActiveTab((state) => {
        if (state === tabsArr.length - 1) {
          return 0;
        }
        return state + 1;
      });

      setSwipeRight(false);
    }

  }, [swipeLeft, swipeRight, posStart, tabsArr.length, setActiveTab])

  const onTouchStart = (e) => {
    setPosStart(e.touches[0].clientX);
  };

  const onTouchEnd = (e) => {
    if (posStart - e.changedTouches[0].clientX > 40) {
      setSwipeRight(true);
    }
    if (posStart - e.changedTouches[0].clientX < -40) {
      setSwipeLeft(true);
    }
  };

  const bodyContent = [
    {
      id: 0,
      data: [
        {
          img: '/icons/tabs/dancers/tabs-dancers1.svg',
          title: 'Персональні сторінки користувачів',
          descr: 'Верифіковані сторінки з історією, балами, рейтингом, фотогалереєю та закладками для користувачів усіх рівнів.'
        },
        {
          img: '/icons/tabs/dancers/tabs-dancers2.svg',
          title: 'Всі документи в смартфоні',
          descr: 'Персональний QR код замінить класифікаційну книжку та копії документів як на заходах, так в поточній роботі.'
        },
        {
          img: '/icons/tabs/dancers/tabs-dancers3.svg',
          title: 'Система кар’єрного росту',
          descr: 'Облік та оцінка балами всіх результатів. Можливість стати тренером та суддею в країнах –партнерах платформи. '
        },
        {
          img: '/icons/tabs/dancers/tabs-dancers4.svg',
          title: 'Контроль класу учасників турнірів ',
          descr: 'В кожній категорії тільки учасники даного класу. Автоматизований перехід в наступний клас.'
        },
        {
          img: '/icons/tabs/dancers/tabs-dancers5.svg',
          title: 'База авторизованих користувачів',
          descr: 'Тільки перевірені та підтверджені документами дані, вільна зміна котрих – неможлива.'
        },
        {
          img: '/icons/tabs/dancers/tabs-dancers6.svg',
          title: 'Онлайн платежі',
          descr: 'Всі платежі з одного кабінету (турнір, клуб, збори тощо). Історія платежів, фільтри по датам.'
        },
      ],
    },
    {
      id: 1,
      data: [
        {
          img: '/icons/tabs/sponsors/tabs-sponsor1.svg',
          title: 'Обмежена кількість турнірів',
          descr: 'Турніри проводять лише ліцензовані платформою організатори.'
        },
        {
          img: '/icons/tabs/sponsors/tabs-sponsor2.svg',
          title: 'Одна дата – один турнір',
          descr: 'Вище статус турніру – більше регіон без змагань на платформі.'
        },
        {
          img: '/icons/tabs/sponsors/tabs-sponsor3.svg',
          title: 'Безготівкові оплати',
          descr: 'Попереднє отримання коштів – зрозумілий баланс заходу.'
        },
        {
          img: '/icons/tabs/sponsors/tabs-sponsor4.svg',
          title: 'Розклад без затримок',
          descr: 'Публікація затвердженого таймінгу за 24 години до початку.'
        },
        {
          img: '/icons/tabs/sponsors/tabs-sponsor5.svg',
          title: 'Рейтинг турнірів',
          descr: 'Незалежне оцінювання якості заходу. Рейтинг – відповідний статус. '
        },
        {
          img: '/icons/tabs/sponsors/tabs-sponsor6.svg',
          title: 'Статусність турнірів',
          descr: 'Статус – перелік відповідних вимог  та категорій до умов проведення.'
        },
      ],
    },
    {
      id: 2,
      data: [
        {
          img: '/icons/tabs/trainer/tabs-trainer1.svg',
          title: 'Тренер і суддя – різні посади',
          descr: 'Відокремлення позицій тренера та судді як на турнірах, так і в поточній роботі.'
        },
        {
          img: '/icons/tabs/trainer/tabs-trainer2.svg',
          title: 'Ліцензування тренерів та суддів',
          descr: 'Ліцензія – висока якість роботи. Високий клас танцюриста – запорука майбутньої ліцензії. '
        },
        {
          img: '/icons/tabs/trainer/tabs-trainer3.svg',
          title: 'Міжнародна система кар’єрного росту',
          descr: 'Автоматизована система підвищення чи пониження категорій для суддів та тренерів. '
        },
        {
          img: '/icons/tabs/trainer/tabs-trainer4.svg',
          title: 'Навчання та контроль без кордонів ',
          descr: 'Онлайн конгреси, тестування, екзамени. Отримуй знання, збагачуй досвід без обмежень.'
        },
        {
          img: '/icons/tabs/trainer/tabs-trainer5.svg',
          title: 'Обмежена кількість суддів',
          descr: 'Кількість суддів залежить від кількості турнірів. Ліцензія судді – гарантія роботи.'
        },
        {
          img: '/icons/tabs/trainer/tabs-trainer6.svg',
          title: 'Календар суддівства',
          descr: 'Статус – перелік відповідних вимог  та категорій до умов проведення.'
        },
      ],
    },
    {
      id: 3,
      data: [
        {
          img: '/icons/tabs/clubs/tabs-clubs1.svg',
          title: 'Міжнародний каталог клубів та організацій',
          descr: 'Каталог – мапа міжнародного співтовариства, місце та реклама в даному просторі.'
        },
        {
          img: '/icons/tabs/clubs/tabs-clubs2.svg',
          title: 'Ваш сайт – частина єдиного простору',
          descr: 'Автоматичне оновлення календаря заходів та інших необхідних вам даних з бази e-phan.'
        },
        {
          img: '/icons/tabs/clubs/tabs-clubs3.svg',
          title: 'Контроль переходу танцюристів та тренерів',
          descr: 'Контроль за переходами між клубами контролюється у відповідності до прийнятих документів.'
        },
        {
          img: '/icons/tabs/clubs/tabs-clubs4.svg',
          title: 'Облік членів та фінансів',
          descr: 'Автоматизований облік членів, їх платежів, внутрішній звіт фінансової діяльності.'
        },
        {
          img: '/icons/tabs/clubs/tabs-clubs5.svg',
          title: 'Створення сайтів',
          descr: 'Сайти з індивідуальним обличчям та з підключенням до спільної бази даних.'
        },
        {
          img: '/icons/tabs/clubs/tabs-clubs6.svg',
          title: 'Геолокація клубів',
          descr: 'Розміщення зареєстрованих клубів та їх філій на єдиній міжнародній мапі, повний опис переваг клубу.'
        },
      ],
    }
  ]

  return (
    <TabsBodyStyled>
      <TabsCircles activeTab={activeTab} tabsArr={tabsArr} setActiveTab={setActiveTab} />
      <Container>
        <BodyWrapper onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
          {
            bodyContent.find(item => item.id === activeTab)
            .data.map((item, i) => <TabsBodyItem key={i} img={item.img} title={item.title} descr={item.descr} />)
          }
        </BodyWrapper>
      </Container>
    </TabsBodyStyled>
  )
}

export default TabsBody;