import React from 'react';
import styled from 'styled-components';

const SectionTitleStyled = styled.div`
  font-weight: bold;
  font-size: 40px;
  line-height: 130%;
  color: ${p => p.theme.textColor};

  @media(max-width: 576px) { 
    font-size: 28px;
  }
`;

const SectionTitle = ({title}) => {
  return (
    <SectionTitleStyled>{title}</SectionTitleStyled>
  )
}

export default SectionTitle;