import React from 'react';
import styled from 'styled-components';
import Container from '../container/Container';
import LangBlock from './langBlock/LangBlock';
import LogInBlock from './logInBlock/LogInBlock';
import MenuBlock from './MenuBlock';
import SerarchBlock from './searchBlock/SerarchBlock';

const HeaderContainer = styled.div`
  box-shadow: 0px 1px 2px rgba(174, 174, 192, 0.2);
`;

const HeaderWrapper = styled.div`
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(max-width: 576px) {
    min-height: 60px;
  }
`;

const HeaderLeftBlock = styled.div`
  width: calc(50% + 50px);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.a`
  width: 115px;
  height: 45px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media(max-width: 576px) {
    width: 102px;
    height: 40px;
  }
`;

const HeaderRightBlock = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media(max-width: 992px) {
    width: unset;
  }

`;

const Header = () => {
  return (
    <HeaderContainer>
    <Container>
      <HeaderWrapper>
        <HeaderLeftBlock>
          <MenuBlock />
          <Logo href='/' ><img src='/icons/logo.png' alt='logo'></img></Logo>
        </HeaderLeftBlock>
        <HeaderRightBlock>
          <SerarchBlock />
          <LangBlock />
          <LogInBlock />
        </HeaderRightBlock>
      </HeaderWrapper>
    </Container>
    </HeaderContainer>
  )
}

export default Header;