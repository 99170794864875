import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import MainSection from './mainSection/MainSection';
import TabsSection from './tabsSection/TabsSection';
import AfterRegisteredSection from './afterRegisteredSection/AfterRegisteredSection';
import Header from './header/Header';
import ForRegSection from './forRegSection/ForRegSection';
import FaqSection from './faqSection/FaqSection';
import FormSection from './formSection/FormSection';
import Footer from './footer/Footer';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.05em;
  }
`;

const theme = {
  mainColor: '#47A5BF',
  textColor: '#223344',
}

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />
      <MainSection />
      <TabsSection />
      <AfterRegisteredSection />
      <ForRegSection />
      <FaqSection />
      <FormSection />
      <Footer />
    </ThemeProvider>
  )
}

export default App;