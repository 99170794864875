import React from 'react';
import styled from 'styled-components';

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const InputMockLabel = styled.div`
  display: ${p => p.show ? 'block' : 'none'};
  position: absolute;
  top: -5px;
  transform: translateY(-100%);
  left: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: ${p => p.valid ? '#FFF' : '#FF0F0F'};
`;

const InputStyled = styled.input`
  width: 100%;
  background: #223344;
  outline: none;
  border: none;
  padding-bottom: 4px;
  border-bottom: 1px solid ${p => p.valid ? '#FFF' : '#FF0F0F'};
  caret-color: #FFF;
  font-weight: ${p => p.valid ? '600' : 'normal'};
  font-size: 15px;
  line-height: 140%;
  color: ${p => p.valid ? '#FFF' : '#FF0F0F'};
  letter-spacing: 0.1em;
  ::placeholder {
    font-weight: normal;
    font-size: 15px;
    line-height: 140%;
    color: ${p => p.valid ? '#FFF' : '#FF0F0F'};
  }
`;

const FormInput = ({value, setValue, type, placeholder, valid, setValid}) => {

  const onChange = (e) => {
    setValue(e.target.value);
    if (e.target.value) {
      setValid(true);
    }
  }

  return (
    <InputWrapper>
      <InputStyled valid={valid} type={type} value={value} onChange={onChange} placeholder={placeholder} />
      <InputMockLabel valid={valid} show={value}>{placeholder}</InputMockLabel>
    </InputWrapper>
  )
}

export default FormInput;