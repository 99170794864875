import React from 'react';
import styled from 'styled-components';

const LogInBlockStyled = styled.a`
  display: flex;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #223344;
  cursor: pointer;
  img {
    max-width: 16px;
    margin-left: 10px;
  }
`;

const LogInTitle = styled.div`
  @media(max-width: 992px) {
    display: none;
  }
`;

const LogInBlock = () => {
  return (
    <LogInBlockStyled href='https://platform.e-phan.com/auth/login'>
      <LogInTitle>Вхід</LogInTitle>
      <img src='/icons/user.svg' alt='user'></img>
    </LogInBlockStyled>
  )
}

export default LogInBlock;