import React, { useState } from 'react';
import styled from 'styled-components';
import Container from '../container/Container';
import FormInput from './FormInput';

const FormSectionStyled = styled.section`
  padding: 80px 0;
  background: #223344;

  @media(max-width: 576px) {
    padding: 40px 0 60px 0;
  }
`;

const FormStyled = styled.form`
  margin-top: 56px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media(max-width: 992px) {
    flex-direction: column;
  }
`;

const FormTitle = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 130%;
  color: #F6F6F6;

  @media(max-width: 576px) {
    font-size: 24px;
  }
`;

const FormLeftBlock = styled.div`
  min-height: 190px;
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media(max-width: 992px) {
    width: 100%;
  }
`;

const FormRightBlock = styled(FormLeftBlock)`
  width: 58%;

  @media(max-width: 992px) {
    margin-top: 56px;
    width: 100%;
  }
`;

const TextAreaWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const InputMockLabel = styled.div`
  display: ${p => p.show ? 'block' : 'none'};
  position: absolute;
  top: -5px;
  transform: translateY(-100%);
  left: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #FFF;
`;

const TextAreaStyled = styled.textarea`
  width: 100%;
  resize: none;
  overflow: hidden;
  height: ${p => p.h};
  min-height: 25px;
  background: #223344;
  outline: none;
  border: none;
  padding-bottom: 4px;
  border-bottom: 1px solid ${p => p.valid ? '#FFF' : '#FF0F0F'};
  caret-color: #FFF;
  font-weight: ${p => p.valid ? '600' : 'normal'};
  font-size: 15px;
  line-height: 140%;
  letter-spacing: 0.1em;
  color: ${p => p.valid ? '#FFF' : '#FF0F0F'};
  ::placeholder {
    font-weight: normal;
    font-size: 15px;
    line-height: 140%;
    color: ${p => p.valid ? '#FFF' : '#FF0F0F'};
  }

  @media(max-width: 576px) {
    font-size: 14px;
  }
`;

const FileBlock = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media(max-width: 992px) {
    flex-direction: column;
  }

  @media(max-width: 576px) {
    margin-top: 56px;
  }
`;

const FileNameBlock = styled.div`  
  width: 75%;

  @media(max-width: 992px) {
    width: 100%;
  }
`;

const FileNameItem = styled.div`
  margin-top: 15px;
  position: relative;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background: #F3F5F7;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.07em;
  color: ${p => p.theme.textColor};
  :first-child {
    margin-top: 0;
  }
  img {
    cursor: pointer;
  }

  @media(max-width: 992px) {
    :first-child {
      margin-top: 15px;
    }
  }
`;

const InputFileLabel = styled.label`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #FFF;
  cursor: pointer;
  img {
    margin-right: 10px;
  }
  input {
    display: none;
  }
`;

const ButtonSubmit = styled.button`
  margin-top: 20px;
  padding: 12px 86px;
  background: #47A5BF;
  border-radius: 18px;
  outline: none;
  border: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.1em;
  color: #FFFFFF;
  cursor: pointer;
  :hover {
    background: #0A87AA;
    transition: all 0.4s;
  }

  @media(max-width: 576px) {
    width: 100%;
    margin-top: 56px;
  }
`;

const FormSection = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);

  const [nameValid, setNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [messageValid, setMessageValid] = useState(true);

  const [textAreaHeight, setTextAreaHeight] = useState('25px');

  const onChangeTextArea = (e) => {
    setTextAreaHeight(`${e.target.scrollHeight}px`);
    setMessage(e.target.value);
    if (e.target.value) {
      setMessageValid(true);
    }
  }

  const onChangeFile = (e) => {
    const fileList = Object.values(e.target.files).filter((item) => isNaN(item));
    setFiles(fileList)
  }

  const onRemoveFile = (idx) => {
    setFiles((state) => {
      return [...state.slice(0, idx), ...state.slice(idx + 1)];
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      setNameValid(false);
    } else {
      setNameValid(true);
    }

    if (!email) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }

    if (!phone) {
      setPhoneValid(false);
    } else {
      setPhoneValid(true);
    }

    if (!message) {
      setMessageValid(false);
    } else {
      setMessageValid(true);
    }
  }

  return (
    <FormSectionStyled>
      <Container>
        <FormTitle>Форма зворотнього зв’язку</FormTitle>
        <FormStyled onSubmit={onSubmit}>
          <FormLeftBlock>
            <FormInput 
              valid={nameValid}
              setValid={setNameValid} 
              type='text' 
              placeholder='Ім’я та Прізвище' 
              value={name} 
              setValue={setName}  />
            <FormInput 
              valid={emailValid} 
              setValid={setEmailValid} 
              type='email' 
              placeholder='e-mail' 
              value={email} 
              setValue={setEmail}  />
            <FormInput 
              valid={phoneValid} 
              setValid={setPhoneValid} 
              type='tel' 
              placeholder='Номер телефону' 
              value={phone} 
              setValue={setPhone}  />
          </FormLeftBlock>
          <FormRightBlock>
            <TextAreaWrapper>
              <TextAreaStyled 
                valid={messageValid} 
                value={message} 
                onChange={onChangeTextArea} 
                h={textAreaHeight} 
                placeholder='Текст повідомлення...'>
              </TextAreaStyled>
              <InputMockLabel show={message}>Текст повідомлення...</InputMockLabel>
            </TextAreaWrapper>
            <FileBlock>
              <InputFileLabel>
                <img src='/icons/file.svg' alt='file'></img>
                Вкласти файл
                <input multiple onChange={onChangeFile} type='file' />
              </InputFileLabel>
              <FileNameBlock>
              {
                  files.map((item, i)=>(
                  <FileNameItem key={i}>
                    {item.name}
                    <img onClick={()=>onRemoveFile(i)} src='/icons/close.svg' alt='close'></img>
                  </FileNameItem>
                ))
              }
              </FileNameBlock>
            </FileBlock>
            <ButtonSubmit type='submit'>Надіслати</ButtonSubmit>
          </FormRightBlock>
        </FormStyled>
      </Container>
    </FormSectionStyled>
  )
}

export default FormSection;