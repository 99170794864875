import React from 'react';
import styled from 'styled-components';
import MainButton from '../buttons/MainButton';
import Container from '../container/Container';

const MainSectionStyled = styled.section`
  min-height: 630px;
  position: relative;
`;

const MainSectionDescr = styled.div`
  width: 50%;
  padding: 121px 80px 113px 0; 

  @media(max-width: 992px) {
    width: 100%;
    padding: 524px 0 113px 0; 
  }

  @media(max-width: 576px) {
    padding: 324px 0 60px 0;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 56px;
  line-height: 130%;
  color: ${p => p.theme.textColor};

  @media(max-width: 576px) {
    font-size: 36px;
    line-height: 125%;
  }
`;

const SubTitle = styled.div`
  margin-top: 32px;
  margin-bottom: 44px;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: ${p => p.theme.textColor};

  @media(max-width: 576px) {
    margin-top: 16px;
    margin-bottom: 28px;
    font-size: 15px;
  }
`;

const ImgBlock = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media(max-width: 992px) {
    width: 100%;
    height: 500px;
  }

  @media(max-width: 576px) {
    height: 300px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  @media(max-width: 992px) {
    justify-content: center;
  }
`;

const MainSection = () => {
  return (
    <MainSectionStyled>
      <Container>
        <MainSectionDescr>
          <Title>Платформа спортивного танцю</Title>
          <SubTitle>Перша верифікована база із міжнародною системою ідентифікації </SubTitle>
          <ButtonWrapper><MainButton href='https://platform.e-phan.com/auth/register' title='Зареєструватися' /></ButtonWrapper>
        </MainSectionDescr>
      </Container>
      <ImgBlock><img src='/images/platform-img.jpg' alt='dancers'></img></ImgBlock>
    </MainSectionStyled>
  )
}

export default MainSection;