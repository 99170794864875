import React from 'react';
import styled from 'styled-components';
import Container from '../container/Container';
import SectionTitle from '../sectionTitle/SectionTitle';
import FaqItem from './FaqItem';

const FaqSectionStyled = styled.section`
  background: #F3F5F7;
  padding: 80px 0 100px 0;

  @media(max-width: 576px) {
    padding: 32px 0 60px 0;
  }
`;

const FaqSectionWrapper = styled.div`
  max-width: 820px;
  margin: 0 auto;
`;

const FaqList = styled.div`
  margin-top: 56px;
`;

const FaqSection = () => {
  return (
    <FaqSectionStyled>
      <Container>
        <FaqSectionWrapper>
          <SectionTitle title='Питання та відповіді' />
          <FaqList>
            <FaqItem 
              title='Що таке система кар’єрного росту?'
              descr='На нашій платформі впроваджується система, котра пов’язує між собою сутності танцюриста, тренера, керівника клубу, судді та організатора змагань. Це зробить неможливим попадання випадкових людей на платформу.
              Відкривати можливість тренувати, створювати клуб, судити чи організовувати змагання зможуть лише ті особи, котрі виконали відповідні умови платформи.' />
            <FaqItem 
              title='Як зареєструвати танцюриста, якому немає 14 років?'
              descr='Згідно законодавства, реєструватися на платформі може особа, котра досягла віку 14 років. Тому що діти не мають права передавати персональні дані, копії своїх документів або документів будь-кого іншого. 
              Виходячи з цього, спочатку має зареєструватися один з батьків або опікун, отримати статус «Користувач», потім додати дані однієї або декількох дітей, копії їх свідоцтв про народження та відправити на верифікацію. 
              Після підтвердження адміністратором платформи вірності та відповідності заповненних даних документам, створяться сторінки танцюристів.' />
            <FaqItem 
              title='Чим відрізняється календар e‑phan від календарів інших сайтів?'
              descr='Наш календар чітко спланований та збалансований у відповідності до статусів змагань, регіону, досвіду організатора та рейтингу його попередніх заходів.
              Календар e‑phan – це не список конкуруючих турнірів, а єдина система змагань, яка надає змогу кар’єрного росту кожному учаснику.' />
            <FaqItem 
              title='Чи можуть не зареєстровані учасники приймати участь у заходах платформи?'
              descr='Для участі в будь-якому змаганні необхідна реєстрація та верифікація користувача. 
              Також повинні зареєструватися: тренер, керівник клубу та суддя, які планують бути присутніми на даному заході.' />
            <FaqItem 
              title='Як додати іконку на головний екран телефону?'
              descr={(<ul>
                <li>Зайти в будь-який браузер на телефоні.</li>
                <li>Набрати у пошуковій строці (або перейти за посиланням) <a href='e-phan.com'>e‑phan.com </a></li>
                <li>В правому верхньому кутку натиснути на три крапки.</li>
                <li>Вибрати пункт «додати на головний екран».</li>
              </ul>)} />
          </FaqList>
        </FaqSectionWrapper>
      </Container>
    </FaqSectionStyled>
  )
}

export default FaqSection;