import React from 'react';
import styled from 'styled-components';

const ContainerStyled = styled.div`
  width: 90%;
  max-width: 1220px;
  margin: 0 auto;

  @media(max-width: 576px) {
    width: 100%;
    padding: 0 24px;
  }
`;

const Container = ({children}) => {
  return (
    <ContainerStyled>{children}</ContainerStyled>
  )
}

export default Container;