import React, { useState } from 'react';
import styled from 'styled-components';

const FaqItemStyled = styled.div`
  margin-top: 40px;
  position: relative;
  :first-child {
    margin-top: 0;
  }
  :after {
    content: '';
    position: absolute;
    border-bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
  }

  @media(max-width: 576px) {
    margin-top: 24px;
  }
`;

const FaqHeader = styled.div`
  padding-left: ${p => p.showDescr ? '40px' : 0};
  padding-right: 40px;
  position: relative;
  font-weight: ${p => p.showDescr ? 'bold' : 600};
  font-size: ${p => p.showDescr ? '22px' : '20px'};
  line-height: 30px;
  color: ${p => p.theme.textColor};
  transition: all 0.2s;
  cursor: pointer;
  :before {
    content: '';
    display: ${p => p.showDescr ? 'block' : 'none'};
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background: ${p => p.showDescr ? p.theme.textColor : p.theme.mainColor};
    border-radius: 10px;
  }
  :after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 15px);
    ${p => p.showDescr ? 'transform: rotate(180deg);' : ''}
    width: 14px;
    height: 30px;
    background: url('/icons/arrow.svg') center center / contain no-repeat;
    transition: all 0.2s;
  }
  :hover {
    ${p => !p.showDescr && p.hover ? `
      font-weight: bold;
      font-size: 22px;
      color: ${p.theme.mainColor};
      padding-left: 40px;
    ` : ''}
    :before {
       ${p => p.hover ? 'display: block;' : ''} 
      }
    :after {
      ${p => !p.showDescr && p.hover ? 'background: url(/icons/arrow-green.svg) center center / contain no-repeat;' : ''}
    }
  }

  @media(max-width: 576px) {
    font-size: 15px;
    line-height: 140%;
    color: ${p => p.showDescr ? p.theme.mainColor : p.theme.textColor};
    :before {
      background: ${p => p.theme.mainColor};
    }
    :after {
      background: ${p => p.showDescr ? 'url(/icons/arrow-green.svg) center center / contain no-repeat' : 'url(/icons/arrow.svg) center center / contain no-repeat'};
    }
  }
`;

const FaqDescr = styled.div`
  padding-bottom: 40px;

  @media(max-width: 576px) {
    padding-bottom: 24px;
  }
`;

const FaqDescrContent = styled.div`
  margin-top: 16px;
  padding: 0 40px;
  display: ${p => p.show ? 'block' : 'none'};
  font-weight: 500;
  font-size: 17px;
  line-height: 150%;
  color: ${p => p.theme.textColor};
  a {
    text-decoration: underline;
    color: ${p => p.theme.mainColor};
  }
  li {
    margin-top: 10px;
    :first-child {
      margin-top: 0;
    }
  }

  @media(max-width: 576px) {
    font-size: 15px;
  }
`;

const FaqItem = ({title, descr}) => {

  const [showDescr, setShowDescr] = useState(false);
  const [hover, setHover] = useState(true);

  return (
    <FaqItemStyled>
      <FaqHeader 
        hover={hover} 
        showDescr={showDescr} 
        onClick={()=>setShowDescr(state=>!state)}
        onTouchStart={()=>setHover(false)}>
          {title}
      </FaqHeader>
      <FaqDescr>
        <FaqDescrContent show={showDescr}>
          {descr}
        </FaqDescrContent>
      </FaqDescr>
    </FaqItemStyled>
  )
}

export default FaqItem;