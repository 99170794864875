import React from 'react';
import styled from 'styled-components';

const MainButtonStyled = styled.a`
  display: block;
  padding: 12px 52px;
  border-radius: 18px;
  background: ${p => p.theme.mainColor};
  outline: none;
  border: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.1em;
  color: #FFFFFF;
  cursor: pointer;

  :hover {
    background: #0A87AA;
    transition: all 0.4s;
  }
`;

const MainButton = ({title, href}) => {
  return (
    <MainButtonStyled href={href} >{title}</MainButtonStyled>
  )
}

export default MainButton;