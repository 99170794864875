import React from 'react';
import styled from 'styled-components';

const MenuBlockStyled = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
`;

const MenuIconStyled = styled.div`
  margin-right: 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 22px;
  height: 18px;
  span {
    width: 100%;
    height: 1.75px;
    background: ${p => p.theme.mainColor};
    border-radius: 1px;
  }
`;

const MenuTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 10px;
  letter-spacing: 0.1em;
  color: #223344;

  @media(max-width: 576px) {
    display: none;
  }
`;

const MenuBlock = () => {
  return (
    <MenuBlockStyled>
      <MenuIconStyled>
        <span></span>
        <span></span>
        <span></span>
      </MenuIconStyled>
      <MenuTitle>Меню</MenuTitle>
    </MenuBlockStyled>
  )
}

export default MenuBlock;