import React from 'react';
import styled from 'styled-components';
import Container from '../container/Container';
import SectionTitle from '../sectionTitle/SectionTitle';

const ForRegSectionStyled = styled.section`
  padding-bottom: 60px;
`;

const SectionWrapper = styled.div`
  display: flex;

  @media(max-width: 992px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const SectionContent = styled.div`
  width: 50%;

  @media(max-width: 992px) {
    width: 70%;
  }

  @media(max-width: 576px) {
    width: 100%;
  }
`;

const StepList = styled.ul`
  margin-top: 62px;
  list-style: none;

  @media(max-width: 576px) {
   margin-top: 32px;
   position: relative;
   :after {
       content: '';
       position: absolute;
       width: 2.5px;
       height: 100%;
       bottom: 10px;
       left: 61px;
       background: #F3F5F7;
       z-index: -1;
       opacity: 1;
     }
  }
`;

const StepListItem = styled.li`
  width: 80%;
  margin-top: 42px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: ${p => p.theme.textColor};
  :first-child {
    margin-top: 0;
  }
  span {
    margin-right: 40px;
    font-weight: normal;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0.15em;
    color: ${p => p.theme.mainColor};
    opacity: ${p => p.opacity || 1};
  }

  @media(max-width: 992px) {
    width: 100%;
  }

  @media(max-width: 576px) {
   margin-top: 24px;
   font-size: 15px;
   span {
     min-width: 40px;
     margin-right: 44px;
     position: relative;
     font-size: 28px;
     :before {
       content: '';
       width: 15px;
       height: 15px;
       position: absolute;
       left: 55px;
       top: calc(50% - 7.5px);
       background: ${p => p.theme.mainColor};
       border-radius: 6px;
     }
   }
  }
`;

const RegButton = styled.a`
  margin-left: 100px;
  margin-top: 52px;
  display: flex;
  padding: 14px 32px;
  border: 2px solid #F3F5F7;
  border-radius: 18px;
  background: #FFF;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${p => p.theme.mainColor};
  cursor: pointer;
  img {
    margin-left: 16px;
  }
  :hover {
    background: #F3F5F7;
    transition: all 0.4s;
  }

  @media(max-width: 576px) { 
    margin: 0 auto;
    margin-top: 32px;
  }
`;

const SectionImg = styled.div`
  width: 50%;
  background: url('/images/steps-visual.png') center center / cover no-repeat;

  @media(max-width: 992px) {
    margin-top: 32px;
    width: 100%;
    height: 500px;
    background: url('/images/steps-visual-mobile.png') center center / contain no-repeat;
  }

  @media(max-width: 576px) {
    height: 250px;
    background: url('/images/steps-visual-mobile.png') center center / cover no-repeat;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const ForRegSection = () => {
  return (
    <ForRegSectionStyled>
      <Container>
        <SectionTitle title='Для реєстрації' />
        <SectionWrapper>
          <SectionContent>
            <StepList>
              <StepListItem opacity='0.2'><span>01</span>Заповніть електронну анкету</StepListItem>
              <StepListItem opacity='0.4'><span>02</span>Вкладіть необхідні документи та фото</StepListItem>
              <StepListItem opacity='0.6'><span>03</span>Очікуйте підтвердження від платформи </StepListItem>
              <StepListItem opacity='0.8'><span>04</span>Отримуєте доступ до акаунту</StepListItem>
              <StepListItem opacity='1'><span>05</span>Користуєтесь перевагами</StepListItem>
            </StepList>
            <ButtonWrapper>
              <RegButton href='https://platform.e-phan.com/auth/register'>Перейти до реєстрації <img src='/icons/arrow-right.svg' alt='arrow-right' ></img></RegButton>
            </ButtonWrapper>
          </SectionContent>
          <SectionImg></SectionImg>
        </SectionWrapper>
      </Container>
    </ForRegSectionStyled>
  )
}

export default ForRegSection;