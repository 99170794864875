import React from 'react';
import styled from 'styled-components';

const TabsBodyItemStyled = styled.div`
  
`;

const ItemImg = styled.img`
max-height: 64px;
@media(max-width: 576px) {
  max-height: 52px;
  }
`;

const ItemTitle = styled.div`
  margin-top: 40px;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  color: ${p => p.theme.textColor};

  @media(max-width: 576px) {
    margin-top: 16px;
    font-size: 20px;
  }
`;

const ItemDescr = styled.div`
  margin-top: 18px;
  font-weight: 500;
  font-size: 17px;
  line-height: 150%;
  color: ${p => p.theme.textColor};

  @media(max-width: 576px) {
    margin-top: 12px;
    font-size: 15px;
  }
`;

const TabsBodyItem = ({img, title, descr}) => {
  return (
    <TabsBodyItemStyled>
      <ItemImg src={img} alt='circle'></ItemImg>
      <ItemTitle>{title}</ItemTitle>
      <ItemDescr>{descr}</ItemDescr>
    </TabsBodyItemStyled>
  )
}

export default TabsBodyItem;