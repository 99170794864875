import React from 'react';
import styled from 'styled-components';
import MainButton from '../buttons/MainButton';
import Container from '../container/Container';
import SectionTitle from '../sectionTitle/SectionTitle';

const AfterRegisteredStyled = styled.section`
  position: relative;
  padding: 100px 0;

  @media(max-width: 576px) {
    padding-bottom: 60px;
  }
`;

const AfterRegisteredWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media(max-width: 992px) {
    display: block;
  }
`;

const SectionImg = styled.div`
  width: 48.5%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media(max-width: 992px) {
    width: 100%;
    height: 500px;
    img {
      object-fit: contain;
    }
  }

  @media(max-width: 576px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    img {
      object-fit: cover;
    }
  }
`;

const SectionContent = styled.div`
  padding-top: 50px;
  width: 45%;

  @media(max-width: 992px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
   }

   @media(max-width: 576px) { 
    padding-top: 330px;
   }
`;

const DescrList = styled.ul`
  list-style: none;
  margin-top: 52px;
  margin-bottom: 70px;

  @media(max-width: 576px) { 
    margin-top: 32px;
    margin-bottom: 48px;
   }
`;

const DescrListItem = styled.li`
  margin-top: 40px;
  position: relative;
  padding-left: 40px;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: ${p => p.theme.textColor};
  :first-child {
    margin-top: 0;
  }
  :before {
    content: '';
    width: 20px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    background: url('/icons/tick.svg');
  }

  @media(max-width: 576px) { 
    font-size: 15px;
    margin-top: 32px;
   }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const AfterRegisteredSection = () => {
  return (
    <AfterRegisteredStyled>
      <Container>
        <AfterRegisteredWrapper>
          <SectionImg><img src='/images/after-reg.png' alt='dancers'></img></SectionImg>
          <SectionContent>
            <SectionTitle title='Після реєстрації отримуєш' />
            <DescrList>
              <DescrListItem>Персональну сторінку з QR кодом</DescrListItem>
              <DescrListItem>Доступ до безготівкових оплат</DescrListItem>
              <DescrListItem>Участь у системі кар'єрного росту</DescrListItem>
              <DescrListItem>Доступ до сторінок інших користувачів</DescrListItem>
              <DescrListItem>Можливість реєстрації на заходи</DescrListItem>
            </DescrList>
            <ButtonWrapper><MainButton href='https://platform.e-phan.com/auth/register' title='Зареєструватися' /></ButtonWrapper>
          </SectionContent>
        </AfterRegisteredWrapper>
      </Container>
    </AfterRegisteredStyled>
  )
}

export default AfterRegisteredSection;