import React from 'react';
import styled from 'styled-components';
import Container from '../container/Container';

const FooterStyled = styled.footer`
  padding: 35px 110px;
  background: #F3F5F7;

  @media(max-width: 992px) {
    padding: 35px 0;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(max-width: 576px) {
    flex-direction: column;
    min-height: 130px;
  }
`;

const FooterItem = styled.div`
  margin: 0 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: ${p => p.theme.textColor};
  text-align: center;
  a {
    color: ${p => p.theme.textColor};
  }
  span {
    white-space: nowrap;
  }
`;

const Footer = () => {
  return (
    <FooterStyled>
      <Container>
        <FooterWrapper>
          <FooterItem>© 2021 <span>e-phan</span></FooterItem>
          <FooterItem><a href='/'>Політика конфіденційності</a></FooterItem>
          <FooterItem><a href='/'>Умови користування</a></FooterItem>
        </FooterWrapper>
      </Container>
    </FooterStyled>
  )
}

export default Footer;