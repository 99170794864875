import React from 'react';
import styled from 'styled-components';

const LangBlockStyled = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #223344;
  cursor: pointer;
  img {
    max-width: 14px;
    margin-left: 5px;
  }

  @media(max-width: 992px) {
    display: none;
  }
`;

const LangBlock = () => {
  return (
    <LangBlockStyled>
      ua
      <img src='/icons/arrow.svg' alt='arrow'></img>
    </LangBlockStyled>
  )
}

export default LangBlock;