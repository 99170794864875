import React from 'react';
import styled from 'styled-components';

const TabsCirclesStyled = styled.div`
  display: none;
  @media(max-width: 992px) { 
    margin: 0 auto;
    margin-bottom: 28px;
    width: 96px;
    display: flex;
    justify-content: space-between;
  }
`;

const TabsCircle = styled.div`
  width: 12px;
  height: 12px;
  border: 1.5px solid #BBC3CC;
  border-radius: 5px;
  background: ${p => p.isActive ? '#334455' : 'transparent'};
`;

const TabsCircles = ({tabsArr, activeTab, setActiveTab}) => {
  return (
    <TabsCirclesStyled>
      {
        tabsArr.map(item=><TabsCircle onClick={()=>setActiveTab(item.id)} isActive={item.id === activeTab} key={item.id}></TabsCircle>)
      }
    </TabsCirclesStyled>
  )
}

export default TabsCircles;